export default function Pill({
  children,
  onClick,
  isRecomended,
  borderless = false,
}) {
  return (
    <div
      className={`
      ${borderless ? "" : "border border-1 "}
      text-sm hover:bg-gray-200 font-semibold rounded-md px-2 ${
        isRecomended
          ? "text-brand-secondary border-brand-secondary"
          : "text-brand-mediumgray border-brand-mediumgray"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
