import Link from "next/link";

export default function ElementsSection({
  title,
  children,
  more = undefined,
  className,
}) {
  return (
    <>
      <h2 className="text-xl font-medium">{title}</h2>
      {children}
      {more && (
        <div className="flex pt-4 justify-center">
          <Link href={more.url}>
            <a>
              <button className="px-12 text-brand-softgray py-3 rounded-md border border-1 border-brand-softgray">
                {more.text ? more.text : more}
              </button>
            </a>
          </Link>
        </div>
      )}
    </>
  );
}
