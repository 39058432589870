import Link from "next/link";
import { event as gaEvent } from "~/lib/ga";

export default function LocalAds() {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div>
        <h5 className="text-lg text-gray-900">Revista SeminuevosSonora.com</h5>
        <Link href="/revista">
          <a
            onClick={() => {
              gaEvent({
                action: "revista_banner_click",
                params: {},
              });
            }}
          >
            <img
              src="/images/revista/portada_revista_112024.jpeg"
              alt="Portada de la revista de SeminuevosSonora.com"
            />
          </a>
        </Link>
      </div>
      <a
        href="https://cortinasmetalicasgarcia.com.mx"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/local_ads/cortinas.gif" alt="Ad cortinas" />
      </a>
      <a href="/" target="_blank" rel="noreferrer">
        <img src="/local_ads/banans.jpg" alt="Ad banans" />
      </a>
<a
        href="https://drive.google.com/file/d/1jRzVEvBAWnLsol4DYrFJc_-2UjEOZP6C/view"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/local_ads/mutuus.jpeg" alt="Ad mutuus" />
      </a>
<a
        href="https://drive.google.com/file/d/1RW8lWdIGu7lpVcwujXDbgtULjtfEK2qx/view"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/local_ads/unseguro.jpeg" alt="Ad metlife" />
      </a>
    </div>
  );
}
