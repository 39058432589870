import Image from "next/image";
import Pill from "~/ui/pill";
import Link from "next/link";
import { convertToMoney } from "~/common/utils/currency";
import { FaStar } from "react-icons/fa";

function RecommendedStar() {
  return (
    <span className="absolute right-1.5 top-1.5 z-10 border-2 p-1 rounded-lg bg-brand-primary border-brand-dark">
      <FaStar />
    </span>
  );
}

export default function Card({
  cover,
  id,
  car,
  price,
  metas,
  slug,
  isRecomended,
}) {
  const carMetas = metas.filter((meta) => {
    return meta.key !== "city" && meta.key !== "state";
  });
  const locationMetas = metas.filter((meta) => {
    return meta.key === "city" || meta.key === "state";
  });

  return (
    <div
      className={`cursor-pointer relative rounded-lg border border-b-1 border-b-brand-gray ${
        isRecomended ? "bg-brand-primary" : "bg-brand-white"
      } `}
    >
      {/* <div className="object-cover object-center"></div> */}
      <Link href={`/auto/${slug}/${id}`} passHref>
        <a className="select-text">
          {isRecomended && <RecommendedStar />}
          <Image
            className="object-cover object-center rounded-t-md"
            src={cover}
            // src="https://seminuevossonora.mx/inicio/autos_old/a2126/a_72450_725166_1715363640.jpg"
            width={640}
            height={480}
            alt="card cover image"
          />
        </a>
      </Link>
      <div className="px-4 py-2 flex flex-col gap-2">
        <Link href={`/auto/${slug}/${id}`} passHref>
          <a>
            <h3 className="font-medium cursor-pointer hover:underline text-xl select-text">
              {car}
            </h3>
          </a>
        </Link>
        <div className="flex gap-1 flex-wrap">
          {carMetas.map((meta, idx) => {
            const { borderless, link, key, value: metaValue } = meta;
            if (metaValue === 0) return;
            let value = metaValue;
            // console.log(value);
            if (key === "km")
              value = Number(value).toLocaleString("es-MX") + " km";
            if (link) {
              return (
                <Link key={idx} href={link}>
                  <a>
                    <Pill
                      isRecomended={isRecomended}
                      borderless={borderless}
                      key={idx}
                    >
                      {value}
                    </Pill>
                  </a>
                </Link>
              );
            }
            return (
              <Pill
                isRecomended={isRecomended}
                borderless={borderless}
                key={idx}
              >
                {value}
              </Pill>
            );
          })}
        </div>
        <div className="flex gap-1 flex-wrap">
          {locationMetas.map((meta, idx) => {
            const { borderless, link, key, value: metaValue } = meta;
            if (metaValue === 0) return;
            let value = metaValue;
            // console.log(value);
            if (key === "km")
              value = Number(value).toLocaleString("es-MX") + " km";
            if (link) {
              return (
                <Link key={idx} href={link}>
                  <a>
                    <Pill
                      isRecomended={isRecomended}
                      borderless={borderless}
                      key={idx}
                    >
                      {value}
                    </Pill>
                  </a>
                </Link>
              );
            }
            return (
              <Pill
                borderless={borderless}
                isRecomended={isRecomended}
                key={idx}
              >
                {value}
              </Pill>
            );
          })}
        </div>
        <p
          onClick={(e) => e.preventDefault()}
          className="text-xl font-medium"
          draggable={false}
        >
          {price ? convertToMoney(Number(price)) : "Negociable"}
        </p>
      </div>
    </div>
  );
}
