import React from "react";
const isProduction = process.env.NODE_ENV === "production";
const GOOGLE_ADSENSE_CA = process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_CA;

export default function GoogleAd({ adSlot }) {
  const loadAds = () => {
    try {
      if (typeof window !== "undefined") {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.log("adsense error", error.message);
    }
  };

  React.useEffect(() => {
    if (isProduction && GOOGLE_ADSENSE_CA) {
      loadAds();
    }
  }, []);

  if (!isProduction) {
    return (
      <div className="w-full h-36 bg-brand-primary">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
          ratione, beatae doloribus explicabo perferendis obcaecati inventore
          facere ullam expedita dolore eveniet totam est ipsum magni velit
          sapiente, animi officia dolor?
        </p>
      </div>
    );
  }

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={GOOGLE_ADSENSE_CA}
      data-ad-slot={adSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
}
