import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CarouselBanner({ images }) {
  const [nav, setNav] = React.useState(null);
  const [slider, setSlider] = React.useState(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    // afterChange: (event) => {
    //   setImageShowing(event);
    // },
    dots: true,
    asNavFor: ".slider-nav",
  };

  return (
    <div className="slide-wrapper">
      <Slider
        {...settingsMain}
        asNavFor={nav}
        ref={(slider) => setSlider(slider)}
      >
        {images.map(({ urlImage, id }) => (
          <div key={id} className="slick-slide">
            <Image
              layout="responsive"
              src={urlImage}
              width={1280}
              height={294}
              alt="Image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
